@import "@smartsuite/react-ui/lib/styles"
@import "@smartsuite/smartdoc/lib/esm/styles"

body
  &.light-mode
    background-color: unset

.rn-editor
  &.ios
    .editor
      position: relative

    .editor-react-plugin-wrapper--EditorStaticToolbarPluginView
      position: sticky
      top: 0
      background: #fff
      z-index: 10000

  .editor-toolbar
    overflow-y: scroll
    scrollbar-width: none

  .editor-toolbar::-webkit-scrollbar
    display: none

  .ProseMirror
    padding: 0 8px

  .ss-whiteboard-wrapper
    max-width: 100%

.read-only
  .editor-toolbar
    display: none

.edit-mode
  &.light-mode
    .editor-toolbar
      background-color: #fff // HACK: fix editor when scrolling on the light mode

  .rn-editor
    padding-top: var(--topbar-height)

    .editor-toolbar
      position: fixed
      top: 0
      width: 100%
      z-index: 10000

    .ProseMirror
      padding: 0 20px

.fix-ios-sticky-header
  -webkit-overflow-scrolling: touch !important
  overflow: auto !important
  height: 100% !important

.ss-excalidraw__modal
  z-index: 10000

.editor-video
  video
    width: 100% !important
    max-width: 400px

.ProseMirror-selectednode
  outline: none !important

.no-padding
  .ProseMirror
    padding: 0 !important

.disable-heading-expand-button
  .ProseMirror
    .heading-expand-button
      display: none !important

.custom-background
  &.dark-mode
    background-color: var(--dark-mode-custom-background-color)
  &.light-mode
    background-color: var(--light-mode-custom-background-color)
